class Lookbook extends HTMLElement {
  constructor() {
    super();

  }

  connectedCallback() {
    const accordionHeaders = document.querySelectorAll('.accordionHeader');
    const accordionContents = document.querySelectorAll('.accordionContent');
    const imageGrid = document.querySelector('.lb-gal-grid-container');
    const wrapper = document.querySelector('.wrapper');
    const body = document.querySelector('body');
    const arrow = document.querySelector('.view-collections arrow');

// Add click event listeners to each accordion header
accordionHeaders.forEach((header, index) => {
  header.addEventListener('click', () => {
    const isActive = accordionContents[index].classList.contains('active');

    // Hide all accordion content elements and remove 'active' class from headers
    accordionContents.forEach((content, idx) => {
      content.classList.remove('active');
      accordionHeaders[idx].classList.remove('active'); // Remove 'active' class from corresponding header
    });

    // Show the clicked content element and add 'active' class to corresponding header
    if (!isActive) {
      accordionContents[index].classList.add('active');
      header.classList.add('active');
    }

    // Check if any accordion header or content is active
    const anyActive = [...accordionHeaders, ...accordionContents].some((el) =>
      el.classList.contains('active')
    );

    // Add or remove 'active' class to/from wrapper based on activity
    if (anyActive) {
      wrapper.classList.add('active');
      body.classList.add('active'); // Add 'active' class to the body
      imageGrid.classList.add('active');
    } else {
      wrapper.classList.remove('active');
      body.classList.remove('active'); // Remove 'active' class from the body
      imageGrid.classList.remove('active');
    }
  });
});

document.addEventListener('DOMContentLoaded', () => {
    const grid_items = document.querySelectorAll('.lb-gal-grid-item');
    const close_items = document.querySelectorAll('.lb-gal-close');
    const prev = document.querySelectorAll('.lb-gal-prev');
    const next = document.querySelectorAll('.lb-gal-next');
    const nmbTxt = document.querySelectorAll('.lb-gal-numbertext');
    const lookbookInner = document.querySelector('.lookbook-inner');
    const viewCollection = document.querySelector('.view-collections');

    grid_items.forEach(item => {
      item.addEventListener('click', function handleClick(event) {
        const index_ = item.getAttribute('index')
        const model_ = item.closest('.lb-gal-grid-inner').getAttribute('data-model')
        openModal(model_)
        currentSlide(index_)
      });
    });

    
    viewCollection.addEventListener('click', (e) => {
      e.preventDefault();
      lookbookInner.classList.toggle('active');
      viewCollection.classList.toggle('active');
      wrapper.classList.toggle('mobile-active');

      if (arrow) {
        arrow.classList.toggle('rotate-180');
      }
    });

    
    close_items.forEach(item => {
      item.addEventListener('click', function handleClick(event) {
        const closePopup = item.closest('.lb-gal-modal').getAttribute('id')
        closeModal(closePopup);
      });
    });
    
  prev.forEach(prv => {
    prv.addEventListener('click', (e) => {
      e.preventDefault();
      plusSlides(-1);
    });
  })

  next.forEach(nxt => {
    nxt.addEventListener('click', (e) => {
      e.preventDefault();
      plusSlides(1);
    })
  })
  
    function openModal(n) {
      document.getElementById(n).style.display = "block";
      document.getElementById(n).classList.add('openpopup');
    }
  
    function closeModal(n) {
      console.log(n);
      document.getElementById(n).style.display = "none";
      document.getElementById(n).classList.remove('openpopup');
    }
  
    var slideIndex = 1;
    showSlides(slideIndex);
  
    function plusSlides(n) {
      slideIndex = parseInt(slideIndex);
      showSlides(slideIndex += n);
    }
  
    function currentSlide(n) {
      showSlides(slideIndex = n);
    }
  
    function showSlides(n) {
        var i;
        var slides = document.querySelectorAll('.openpopup .lb-gal-mySlides');
        var totalSlides = slides.length;
        var slideIndex = n;
        
        if (n > slides.length) {
          slideIndex = 1;
        }
        if (n < 1) {
          slideIndex = slides.length;
        }
        
        for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
          slides[i].className = slides[i].className.replace(" active", "");
        }
        
        var counter = "LOOK " + slideIndex + " / " + totalSlides;
        nmbTxt.forEach(function(nmb) {
          nmb.innerHTML = counter;
        });
        
        slides[slideIndex - 1].style.display = "block";
        slides[slideIndex - 1].className += " active";
      }


  // JS to move slider arrows on modal open on mobile only.

  window.addEventListener('DOMContentLoaded', function() {
    let windowSize = window.innerWidth;
    
    if (windowSize <= 614) {
      let containerNodes = document.querySelectorAll('.lb-gal-mySlides');
      let MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
      let observer = new MutationObserver(mutationHandler);
      let obsConfig = {
        childList: true,
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class'],
        subtree: true
      };
  
      containerNodes.forEach(function(containerNode) {
        observer.observe(containerNode, obsConfig);
      });
  
      function mutationHandler(mutationRecords) {
        mutationRecords.forEach(function(mutation) {
          if (mutation.attributeName === 'class' && mutation.target.classList.contains('lb-gal-mySlides', 'active')) {
            setTimeout(function() {
              let imgHeight = document.querySelector('.lb-gal-mySlides.active img').height;
              let arrowAdjustment = 20 + imgHeight;
  
              document.querySelector('.lb-gal-prev-next-container').style.top = arrowAdjustment + 'px';
            }, 700);
          }
        });
      }
    }
  });
  
  }); 

const items = document.querySelectorAll('.accordionHeader');
const hiddenDivs = document.querySelectorAll('.lb-gal-grid-inner');

items.forEach(item => {
  item.addEventListener('click', () => {
    const dataIndex = item.getAttribute('data-index');
    
    hiddenDivs.forEach(div => {
      div.style.display = 'none';
    });
    
    const targetDiv = document.getElementById(`productImg-${dataIndex}`);
    if (targetDiv) {
      targetDiv.style.display = 'grid';
    }
  });
});

  }
}

customElements.define('look-book', Lookbook);
